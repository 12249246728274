import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const WhySecurityTestPageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  assessmentsection,
  softwaresection,
  publicsection,
  regularitysection,
  sourcecodesection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
            </div>
            <div className="column is-4">
              <a href={contactus.buttonlink}>
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "2rem" }}>
            <Title title={aboutsection.title} color={aboutsection.textcolor} />
            <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
            <SubTitle title={aboutsection.subheader} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="1rem 0" />
          </div>
          <div className="columns" style={{ height: 80 }}>
            <div className="column is-6"></div>
            <div className="column is-6 mobile-center" >
              <div style={{ maxWidth: 300 }}>
                <PreviewCompatibleImage imageInfo={aboutsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${assessmentsection.sectionbg}` }}>
        <div className="container">
          <div style={{ margin: "5rem 0" }}>
            <Title title={assessmentsection.title} color={assessmentsection.textcolor} />
            <Title title={assessmentsection.secondtitle} color={assessmentsection.textcolor} />
            <SubTitle title={assessmentsection.subheader} color={assessmentsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={assessmentsection.descriptions} color={assessmentsection.textcolor} margin="1rem 0" />
            <div>
              {
                assessmentsection.requires.map((require, i) => {
                  return (
                    <p key={i}
                      style={{ color: `${assessmentsection.textcolor}` }}
                    >
                      - {require}
                    </p>
                  )

                })
              }
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ margin: "4rem 0" }}>
        <div className="container">
          <div>
            <Title title={softwaresection.title} />
            <Title title={softwaresection.secondtitle} />
            <SubTitle title={softwaresection.subheader1} margin="1.5rem 0" />
            <Descriptions descriptions={softwaresection.descriptions1} />
            <SubTitle title={softwaresection.subheader2} margin="1.5rem 0" />
            <Descriptions descriptions={softwaresection.descriptions2} />
          </div>
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column is-4 has-text-centered">
              <button
                style={{ background: `${softwaresection.buttoncolor}`, width: "80%", marginTop: "3rem" }}
              >{softwaresection.buttontxt}
              </button>
            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${publicsection.sectionbg}` }}>
        <div className="container" style={{ padding: "4rem 0" }}>
          <div className="columns" >
            <div className="column is-4">
              <div style={{ maxWidth: 300, margin: "auto" }}>
                <PreviewCompatibleImage imageInfo={publicsection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Title title={publicsection.title} color={publicsection.textcolor} />
              <Title title={publicsection.secondtitle} color={publicsection.textcolor} />
              <SubTitle title={publicsection.subheader1} margin="1.5rem 0" color={publicsection.textcolor} />
              <Descriptions descriptions={publicsection.sidedescriptions} color={publicsection.textcolor} />
            </div>
          </div>
          <SubTitle title={publicsection.subheader2} margin="1.5rem 0" color={publicsection.textcolor} />
          <Descriptions descriptions={publicsection.descriptions} color={publicsection.textcolor} />
        </div>
      </section>
      <section className="section" style={{ margin: "4rem 0" }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <Title title={regularitysection.title} />
              <Title title={regularitysection.secondtitle} />
              <SubTitle title={regularitysection.subheader} margin="1.5rem 0" />
              <Descriptions descriptions={regularitysection.descriptions} />
            </div>
            <div className="column is-4">
              <div style={{ maxWidth: 300, margin: "auto" }}>
                <PreviewCompatibleImage imageInfo={regularitysection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${sourcecodesection.sectionbg}` }}>
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <div style={{ maxWidth: 300, margin: "auto" }}>
                <PreviewCompatibleImage imageInfo={sourcecodesection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Title title={sourcecodesection.title} color={sourcecodesection.textcolor} />
              <Title title={sourcecodesection.secondtitle} color={sourcecodesection.textcolor} />
              <SubTitle title={sourcecodesection.subheader} color={sourcecodesection.textcolor} margin="1.5rem 0" />
              <p style={{ color: `${sourcecodesection.textcolor}` }}>{sourcecodesection.description1} </p>
              <div>
                {
                  sourcecodesection.requires.map((require, i) => {
                    return (
                      <p key={i}
                        style={{ color: `${sourcecodesection.textcolor}` }}
                      >
                        - {require}
                      </p>
                    )

                  })
                }
              </div>
            </div>
          </div>
          <p style={{ color: `${sourcecodesection.textcolor}`, paddingBottom: "3rem" }}>{sourcecodesection.description2} </p>
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

WhySecurityTestPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  assessmentsection: PropTypes.object,
  softwaresection: PropTypes.object,
  publicsection: PropTypes.object,
  regularitysection: PropTypes.object,
  sourcecodesection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const WhySecurityTestPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <WhySecurityTestPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        assessmentsection={frontmatter.assessmentsection}
        softwaresection={frontmatter.softwaresection}
        publicsection={frontmatter.publicsection}
        regularitysection={frontmatter.regularitysection}
        sourcecodesection={frontmatter.sourcecodesection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

WhySecurityTestPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default WhySecurityTestPage

export const pageQuery = graphql`
  query WhySecurityTestPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "why-security-testing" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        assessmentsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          requires
        }
        softwaresection {
          title
          secondtitle
          subheader1
          descriptions1
          subheader2
          descriptions2
          buttontxt
          buttoncolor
          buttonlink
        }
        publicsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          sidedescriptions
          subheader2
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 460, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        regularitysection {
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 460, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sourcecodesection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          description1
          requires
          description2
          image {
            childImageSharp {
              fluid(maxWidth: 460, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
